import React from "react"
import { navigate } from "gatsby"

const Header = props => {
  let path = "/"
  if (typeof window !== "undefined") {
    path = window.location.pathname
  }

  const goToNavbar = () => {
    navigate("/Bavegna")
  }

  if (path === "/") {
    return (
      <section className="hero is-fullheight background">
        <div className="hero-head"></div>
        {/* logo has-background-white */}
        <div className="hero-body is-centered"></div>
        <div className="hero-footer">
          <div className="container has-text-centered">
            <a
              onClick={() => goToNavbar()}
              className="button is-large is-uppercase is-outlined is-inverted is-rounded is-primary has-text-weight-bold more"
            >
              Mehr erfahren
            </a>
          </div>
        </div>
      </section>
    )
  } else {
    return null
  }
}

export default Header
