import React from "react"
import ReactJkMusicPlayer from "react-jinke-music-player"
import { view } from "react-easy-state"
import playerStore from "./store"

class Player extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      options: null,
    }
  }
  async componentDidMount() {
    const audioLists = await this.createAudioPlaylist()
    playerStore.audioLists = audioLists
    this.setState({ options: playerStore })
  }

  createAudioPlaylist = async () => {
    const clSongs = await this.getSongsfromCloudinary()

    const audioLists = []
    if (clSongs) {
      clSongs.resources.map(song => {
        audioLists.push({
          created: song.created_at,
          name: song.public_id.replace("CVBS/", "").replace(/_/g, " "),
          singer: "Chor Viril Baselgia Savognin",
          musicSrc: `https://res.cloudinary.com/rewasa/video/upload/v${song.version}/${song.public_id}.${song.format}`,
          cover: `https://res.cloudinary.com/rewasa/image/upload/b_rgb:000000,c_thumb,o_50,h_500,w_500/v1566282867/CVBS/CVBS-Alle.jpg`,
        })
      })
      return audioLists
    }
  }

  getSongsfromCloudinary = async () => {
    try {
      const response = await fetch(
        "https://res.cloudinary.com/rewasa/video/list/cvbs-song.json"
      )
      if (response.ok) {
        return await response.json()
      }
    } catch (error) {
      throw error
    }
  }

  onAudioDownload = async AudioInfo => {
    const { name } = AudioInfo
    const mail = await this.prepareCDBuyMail(name)
    window.location.href = mail
  }

  prepareCDBuyMail = name => {
    const EMAIL = "info@chorviril-savognin.ch"
    const SUBJECT = `Chor Viril Baselgia Savognin CD Shop`
    const BODY = `Guten Tag

Ich höre gerade das Lied <<${name}>> und würde gerne ein Album davon kaufen.
  
Meine Adresse:


Chor Viril Baselgia Savognin

`
    return `mailto:${EMAIL}?subject=${encodeURIComponent(
      SUBJECT
    )}&body=${encodeURIComponent(BODY)}`
  }

  render() {
    let { options } = this.state
    return (
      options && (
        <ReactJkMusicPlayer
          {...options}
          onAudioDownload={this.onAudioDownload}
        />
      )
    )
  }
}

export default view(Player)
