/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import "./layout.scss"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { StickyContainer } from "react-sticky"
import Header from "./header"
import Navbar from "./navbar"
import Player from "./player"
import Logo from "../images/logo.svg"
import { withResizeDetector } from "react-resize-detector"
import SEO from "./seo"
import { Link } from "gatsby"

const Layout = ({ children, width, location, ...rest }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allWpPage {
        edges {
          node {
            id
            slug
            title
          }
        }
      }
    }
  `)
  return (
    <>
      {/* <Header /> */}
      <SEO title="Chor Viril Baselgia Savognin" />
      <StickyContainer>
        <Navbar pages={data} player={rest} />
        <div
          style={{
            margin: width <= 780 ? `0 auto` : `0 4rem 0 1.5rem`,
            maxWidth: 1200,
            padding: `0px 1.45rem 1.45rem`,
            paddingTop: 5,
            display: `flex`,
            minHeight: `100vh`,
            flexDirection: `column`,
          }}
        >
          <main style={{ flex: 1, paddingTop: 20 }}>{children}</main>

          <footer className="footer">
            <div
              className="content has-text-centered"
              style={{ paddingTop: 80, paddingBottom: 80 }}
            >
              <img
                src={Logo}
                width="80"
                height="80"
                className="image has-image-centered"
                style={{ paddingBottom: 30 }}
              />
              <p>
                ©{new Date().getFullYear()}
                {` `}- Chor Viril Baselgia Savognin
                {` `}- <Link to="/datenschutzerklarung">Datenschutz</Link>
              </p>
            </div>
          </footer>
        </div>
      </StickyContainer>
      <Player theme={this} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withResizeDetector(Layout)
