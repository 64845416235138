module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-layout@4.12.0_gatsby@5.12.9/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/layout.js"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.12.3_gatsby@5.12.9_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"chor-viril-baselgia-savognin","short_name":"cvbs","start_url":"/","background_color":"#1F599F","theme_color":"#c53a33","display":"minimal-ui","icon":"src/images/logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9126a5b53d8ff9fa90bedb88f6aeada7"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.12.0_gatsby@5.12.9/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-source-wordpress@7.12.3_gatsby-plugin-image@3.12.3_gatsby-plugin-sharp@5.12.3_gatsby-t_p3ysviylhtf5mnmqyfuubumfti/node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://cvbs-proxy.fly.dev/graphql","schema":{"perPage":1,"requestConcurrency":1,"previewRequestConcurrency":1,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000},"type":{"MediaItem":{"localFile":{"requestConcurrency":1,"excludeByMimeTypes":[],"maxFileSizeBytes":15728640},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"Taxonomy":{"limit":0},"UserRole":{"limit":0},"User":{"limit":0},"Category":{"limit":0},"Comment":{"limit":0},"ContentType":{"limit":0},"Menu":{"limit":0},"MenuItem":{"limit":0},"PostFormat":{"limit":0},"Tag":{"limit":0},"Post":{"limit":0},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.12.9_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.3.2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
