import React from "react"
import "./layout.scss"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Logo from "../images/logo.svg"
import { Sticky } from "react-sticky"
import { view } from "react-easy-state"
import playerStore from "./store"

const NavbarItem = (props) => {
  const { page } = props
  return (
    <Link
      onClick={props.toggleMenu}
      className="navbar-item is-uppercase has-text-weight-bold"
      to={page.slug === "home" ? "/" : `/${page.slug}`}
    >
      {page.title}
    </Link>
  )
}
const NavbarBurger = (props) => (
  <button
    onClick={props.toggleMenu}
    className={`button navbar-burger ${props.active ? "is-active" : ""}`}
  >
    <span />
    <span />
    <span />
  </button>
)

class Navbar extends React.Component {
  state = {
    activeMenu: false,
    showedFullPlayerFirstTime: false,
  }
  toggleMenu = () => {
    this.setState({
      activeMenu: !this.state.activeMenu,
    })
  }

  showMusicPlayer = () => {
    playerStore.mode = "full"
    this.setState({ showedFullPlayerFirstTime: true })
  }

  render() {
    const { pages = [] } = this.props
    const { allWpPage } = pages
    const { edges } = allWpPage
    const navbarItems = edges
      .filter((e) => {
        const { node } = e
        return node.slug !== "datenschutzerklarung"
      })
      .map((page) => {
        const { node } = page
        return (
          <NavbarItem page={node} key={node.id} toggleMenu={this.toggleMenu} />
        )
      })
    const { activeMenu, showedFullPlayerFirstTime } = this.state

    return (
      <>
        <Sticky>
          {({ style }) => (
            <nav
              id="nav"
              style={style}
              className="navbar is-spaced has-background-light"
            >
              <div className="navbar-brand">
                <Link to="/" className="navbar-item is-hidden-tablet">
                  <img src={Logo} width="40" height="40" alt="Logo CVBS" />
                </Link>
                <NavbarBurger
                  active={activeMenu}
                  toggleMenu={this.toggleMenu}
                />
              </div>
              <div className={`navbar-menu ${activeMenu ? "is-active" : ""}`}>
                <div className="navbar-start">{navbarItems}</div>
                <div className="navbar-end">
                  {!showedFullPlayerFirstTime && (
                    <a
                      onClick={this.showMusicPlayer}
                      className="navbar-item is-uppercase has-text-weight-bold"
                    >
                      Lieder
                    </a>
                  )}
                  {/* <Link
                    to="/programm"
                    className="navbar-item is-uppercase has-text-weight-bold"
                  >
                    Programm
                  </Link> */}
                  {/* <Link
                    to="/galerie"
                    className="navbar-item is-uppercase has-text-weight-bold"
                  >
                    Galerie
                  </Link> */}
                  {/* <Link
                    to="/videos"
                    className="navbar-item is-uppercase has-text-weight-bold"
                  >
                    Videos
                  </Link> */}
                  {/* <Link to="/" className="navbar-item is-hidden-mobile">
                    <img src={Logo} width="40" height="40" alt="Logo CVBS" />
                  </Link> */}
                </div>
              </div>
            </nav>
          )}
        </Sticky>
      </>
    )
  }
}

Navbar.propTypes = {
  pages: PropTypes.object.isRequired,
  color: PropTypes.string,
}

export default view(Navbar)
