import React from "react"
import { store } from "react-easy-state"
import { FaMusic, FaPlusCircle } from "react-icons/fa"

const playerStore = store({
  audioLists: "",
  theme: "light",
  mode: "mini",
  autoPlay: false,
  defaultPosition: {
    bottom: 10, //width <= 780 ? 0 : 20,
    right: 10, // width <= 780 ? 10 : 20,
  },
  playModeText: {
    order: "Liste",
    orderLoop: "Schleife",
    singleLoop: "Schleife",
    shufflePlay: "Shuffle",
  },
  openText: <FaPlusCircle color="white" size="25" />,

  //audio controller close text  [ type `String | ReactNode` default 'close']
  closeText: "Schliessen",

  //audio theme switch checkedText  [ type `String | ReactNode` default '-']
  checkedText: "gewählt",

  //audio theme switch unCheckedText [ type `String | ReactNode` default '-']
  unCheckedText: "nicht gewählt",

  // audio list panel show text of the playlist has no songs [ type `String` | ReactNode  default 'no music']
  notContentText: "",

  panelTitle: "Lieder",
  showThemeSwitch: false,
  controllerTitle: <FaMusic color="white" style={{ marginTop: 3 }} />,
  remember: false,
})

export default playerStore
